import React,{useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import ExploreProject from '../../components/exploreProject';
import ProjectClient from './projectClient';
import ExpertiseArea from './expertiseArea';
import DeliveryApproach from './deliveryApproach';


const Expertise = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: "Expertise", url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="Expertise" bannerimage="expertise_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="At Simon India Limited, our diverse capabilities span four pivotal sectors: Chemicals & Fertilizers, Oil & Gas, Refinery & Petrochemicals, and Cement & Power. As a leading EPC (Engineering, Procurement, Construction) service provider, we are adept at delivering tailored solutions that cater to the specific needs of each sector. Our experience is backed by advanced technical know-how, ensuring that projects are executed with precision, efficiency, and adherence to the highest industry standards." />
        {/* <ExpertiseProject /> */}
        <ExpertiseArea />
        <ProjectClient 
            quotation="At Simon India, value creation has been a core principle since the beginning. As a leading EPCM company, Simon stands out by fostering a unique blend of cultural values. The trust placed in its skilled employees empowers them to 'think right, act right,' making each individual feel valued and integral to the company’s success."
            client="Mr. Harish Kumar"
            designation="Business Development Head- Simon India Limited. "
        />
        <DeliveryApproach />
        <ExploreProject />
        <AdvantageC />
    </Grid>
)

}

export default Expertise;