import React from 'react';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import styles from './expertise.module.css';
import { Typography, Button } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

const HomeExpertise = () => {
    const navigate = useNavigate()

    return (
        <Grid container spacing={2} className={styles.expertise_container}>
            <Grid item size={{ xs:12 }} className={styles.expertise_subcontainer}>
                <Grid item columns={{xs:12, sm:6}} className={styles.grid_container}>
                    <Typography variant="h2" component={"h2"} className={styles.expertise_header}> Our Areas of Expertise</Typography>
                    <Typography variant="body2" component={"p"} className={styles.expertise_content}> Simon India Ltd., an Adventz Group company, is a leading EPCM services provider specializing in fertilizers, chemicals, oil & gas, power, and infrastructure sectors. With expertise in comprehensive engineering, procurement assistance , construction management, and commissioning, Simon India delivers world-class solutions, focusing on sustainability, innovation, and excellence in project execution across diverse industrial sectors.</Typography>
                    <Button className={styles.expertise_button} onClick={() => navigate("/expertise")} variant="text" endIcon={<EastIcon />}>Know more</Button>
                </Grid>
                <Grid item size={{xs:12, sm:6}} style={{display:"flex", flexFlow:"column", rowGap:"30px"}}>
                    <Grid className={styles.expertise_image_container}>
                        <img src="/assets/images/expertise_1.png" alt="expertise 1" />
                        <img src="/assets/images/expertise_2.png" alt="expertise 2" />
                    </Grid>
                    <Grid className={styles.expertise_image_container}>
                    <img src="/assets/images/expertise_3.png" alt="expertise 3" />
                    <img src="/assets/images/expertise_4.png" alt="expertise 4" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )


}

export default HomeExpertise;