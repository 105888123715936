import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Typography, Card, CardContent, CardMedia, Button } from '@mui/material';

import styles from './homeNews.module.css'
import { ArrowForward } from '@mui/icons-material';



const HomeNews = () => {
    const navigate = useNavigate();

    return (
        <Grid className={styles.homenews_container}>
            <Grid className={styles.homenews_subcontainer}>
                <Typography variant='h2' component={"h2"} className={styles.news_title}>Updates</Typography>
                <Grid className={styles.cardContainer}>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news2.png"
                        title="news1"
                        />
                        <CardContent className={styles.cardContent}>
                            <Typography gutterBottom variant="h3" component="h3" className={styles.newscardtitle}>
                            Simon India Limited gains Insight into procurement processes
                            </Typography>
                            <Typography variant="body2" className={styles.newscard_content}>
                                August 10, 2024
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news3.png"
                        title="news1"
                        />
                        <CardContent className={styles.cardContent} sx={{backgroundColor: "#ECF2F7"}}>
                        <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                            Simon India Participates in New Delhi Roundtable on Hydrogen Technology
                        </Typography>
                        <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                            August 10, 2024
                        </Typography>
                        </CardContent>
                    </Card>
                    <Card sx={{ maxWidth: 336, boxShadow: 'none' }}>
                        <CardMedia 
                        sx={{ height: 216, marginBottom:1 }}
                        image="/assets/images/news1.png"
                        title="news1"
                        />
                        <CardContent sx={{backgroundColor: "#ECF2F7"}} className={styles.cardContent}>
                        <Typography gutterBottom variant="h3" component="h3" className={`${styles.newscardtitle} ${styles.newscard_tcolor}`}>
                            Simon India Limited Secures Major Contract with Paradeep Phosphates Limited
                        </Typography>
                        <Typography variant="body2" className={`${styles.newscard_content} ${styles.newscard_ccolor}`}>
                            July 31, 2024
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid className={styles.news_button}>
                    <Button variant='text' onClick={() => navigate("/news")} className={styles.newsButton} endIcon={<ArrowForward />}>View all</Button>
                </Grid>
            </Grid>

        </Grid>
    )

}

export default HomeNews;