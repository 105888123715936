import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
// import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import AboutExplore from '../aboutus/aboutExplore';
import FaqContainer from './faqContainer';
import { isMobile } from 'react-device-detect';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { Typography, Accordion, AccordionDetails, AccordionSummary, Box} from '@mui/material';
import styles from './faqpage.module.css';

const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none"
          },
          ".expandIconWrapper": {
            display: "none"
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block"
          }
        }}
      >
        <RemoveIcon className={`${styles.accord_icon} expandIconWrapper`} />
        <AddIcon className={`${styles.accord_icon} collapsIconWrapper`} />
      </Box>
    );
  };

const FaqPage = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Projects', url: ''},
        {title: "FAQs", url: ''}
    ]

    useEffect(()=>{
            window.screenTop = 0
    }, [])

    
    const faqContent = <>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={ <CustomExpandIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>Who is a Subject Matter Expert (SME)?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                A Subject Matter Expert (SME) is a highly knowledgeable professional with deep expertise in specific areas such as engineering, procurement, or construction. The SME provides specialized guidance, insights, and solutions tailored to complex project requirements within the group. Their role involves offering technical advice, ensuring best practices are followed, and addressing intricate challenges to support successful project outcomes and enhance overall project execution.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How does Simon India Limited help in finalising Conceptual Design?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                We integrate client requirements and provide technical expertise to enhance design efficiency and performance. We help in finalising the basic design engineering & detail engineering using advanced simulation and modeling tools. After completion of plant set up we do Performance Guarantee Monitoring test to check performance standard. This comprehensive approach ensures that the final design is practical, efficient, and meets the  client’s objectives.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>Can Simon India help us as a consultant even if we have taken Basic engineering from somewhere else?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Yes, Simon India can provide consultancy services even if basic engineering has been sourced elsewhere. We offer residual engineering to refine existing designs and ensure they meet project needs. Our team integrates smoothly with existing teams to address gaps and challenges, offering comprehensive solutions like process engineering, procurement, and construction management, all tailored to enhance your project’s success. We also leverage our deep industry knowledge and expertise of our SMEs to provide innovative solutions and improvements as required for successful completion of your project.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What are the components of the Detailed Project Plan of Simon India Limited?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                The Detailed Project Plan (DPP) at Simon India Limited includes several key components for thorough planning and successful execution:
                <ul style={{listDecoration:"none"}}>
                <li>Project Scope: Defines objectives, deliverables, and boundaries.</li>
                <li>Schedule: Outlines timelines, milestones, and deadlines.</li>
                <li>Budget: Provides cost estimates and financial plans.</li>
                <li>Resources: Specifies required human, technical, and material resources.</li>
                <li>Risk Management: Identifies potential risks and mitigation strategies.</li>
                <li>Quality Management: Establishes quality standards and control measures.</li>
                <li>Communication Plan: Details stakeholder communication and reporting.</li>
                <li>Procurement Plan: Outlines procurement processes and contract management.</li>
                <li>Compliance and Regulatory Requirements: Ensures adherence to legal standards.</li>
                <li>HSE Plan: Addresses health, safety, and environmental concerns.</li>
                </ul>
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What HSE Services does Simon India Limited provide?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                At Simon India Limited, HSE (Health, Safety, and Environment) services are integral to our project execution, focusing on workforce safety, minimizing environmental impact and effective waste and emissions management. We implement comprehensive safety management systems, conduct rigorous risk assessments, and enforce stringent safety protocols to prevent accidents and ensure compliance with regulations.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How Simon India Limited assists with process revamping or De-bottlenecking? </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Simon India Limited’s de-bottlenecking services focus on optimizing system performance by identifying and addressing constraints. Services include analysing processes to locate bottlenecks, designing and implementing solutions to improve flow, and monitoring performance to ensure effectiveness. Our goal is to enhance operational efficiency, increase capacity, and streamline processes to achieve better overall productivity and system performance.
            </Typography>
            </AccordionDetails>
        </Accordion>
    </>
    const faqFooterContent = <>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What commissioning services does Simon India Limited provide?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                At Simon India Limited, commissioning services ensure that systems and equipment meet design specifications and function optimally. This is achieved through rigorous testing, verification, and documentation review, as well as periodic training for operators and maintenance staff. Our commissioning process aims to verify performance, check efficiency, and ensure compliance with all relevant standards, delivering reliable and high-quality outcomes for our projects.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What makes Simon India Limited different from other companies?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Simon India Limited differentiates itself in the EPC and EPCM sectors through its deep expertise in the Chemical, Fertilizer, Oil & Gas industries, offering comprehensive solutions across engineering, procurement, construction, and management. With a strong focus on quality, safety, and client satisfaction, Simon India Limited combines a proven track record of successful project delivery with a client-centric approach and robust post-project support. A key aspect of our approach is the integration of assistance from numerous subject matter experts, ensuring that each project benefits from specialized knowledge and cutting-edge innovations.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel9-content"
            id="panel9-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What is your experience with international projects? Are there any specific continents/ countries that you are looking to explore for more projects?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Simon India has significant experience in international projects, particularly in the Chemicals, Fertilizer, and Oil & Gas industries. We’ve successfully delivered projects in regions like the Middle East and Southeast Asia, adapting to local regulations. We're also actively exploring emerging markets, aiming to expand our global footprint through innovative solutions and strong industry partnerships.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel10-content"
            id="panel10-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>What strategies do you use to communicate effectively across different time zones and cultures?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                We use several strategies to ensure effective communication across time zones and cultures. Digital tools like video conferencing and collaborative platforms enable real-time interaction. We schedule flexible meetings to accommodate time differences and emphasize cultural sensitivity through training. Clear documentation ensures consistency, and dedicated liaisons manage international communications to streamline coordination and promptly address any concerns.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel1-content"
            id="panel11-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How do you ensure adherence to international compliance standards?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                We ensure adherence to international compliance standards by conducting thorough risk assessments to address legal and regulatory issues, including international trade laws. Regular audits and reviews are performed throughout the project to maintain compliance. Additionally, we prioritize transparent communication with stakeholders to promptly resolve any legal or contractual concerns, keeping all parties informed and aligned.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion className={styles.accord}>
            <AccordionSummary
            expandIcon={<CustomExpandIcon />}
            aria-controls="panel12-content"
            id="panel12-header"
            >
            <Typography variant='body2' component={"p"} className={styles.accordian_summary}>How does Simon India Limited help in technology selection?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Simon India Limited assists in technology selection by thoroughly assessing project needs and evaluating various technologies for performance, cost, and compatibility. We conduct feasibility studies to ensure each option meets practical and economic requirements. By analysing potential risks and aligning technology choices with project goals, we ensure the selected technology optimizes performance and efficiency, setting the stage for successful project execution and delivery.
            </Typography>
            </AccordionDetails>
        </Accordion>
    </>

return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="FAQs" bannerimage="faq_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="Welcome to the Simon India FAQ page, where you'll find answers to common questions about our services, expertise, and approach to project execution. Whether you're curious about our experience in international markets, compliance strategies, or how we collaborate with clients, this section provides valuable insights into how we work." />
        <FaqContainer content = {faqContent} marginTop="0px"/>
        <AboutExplore />
        <FaqContainer content={faqFooterContent} marginTop= {!isMobile ? "120px" : "80px"} />
    </Grid>
)

}

export default FaqPage;