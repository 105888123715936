import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import Axios from 'axios';
import { Typography, Button  } from '@mui/material';

import styles from './supplyForm.module.css';
// import { } from './supplyForm.css';
import { East } from '@mui/icons-material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { isMobile } from 'react-device-detect';
import { validateEmail } from '../../../utils/util';


const SupplyForm = () => {

    const location = useLocation();
    // const {name} = useParams();
    // const [requirement, setRequirement] = useState(-1);
    const [validate, setValidate] = useState({
        bool: false,
        msg: ''
    });
    const pathPart = location.pathname.split("/");
    const navurl = pathPart[pathPart.length-1];
    const [status, setStatus] = useState(false);

    const [userInform, setUserInform] = useState({
        fname: '',
        lname:'',
        usermail: '',
        designation: '',
        userstate: '',
        usercountry: '',
        companyname:'',
        compsite: '',
        compregnumber: '',
        compdesc:'',
        compyears: '',
        compemployee: '',
        compprimaryfocus: '',
    })

    const [firstInform, setFirstInform] = useState ({
        fname: false,
        lname:false,
        usermail: false,
        designation: false,
        userstate: false,
        usercountry: false,
        companyname:false,
        compsite: false,
        compregnumber: false,
        compdesc:false,
        compyears: false,
        compemployee: false,
        compprimaryfocus: false,
        
    })

      const handleSubmit = (event) => {
        event.preventDefault();
        const { fname, lname, usermail, designation, userstate, usercountry, companyname, compsite, compregnumber, compdesc, compyears, compemployee, compprimaryfocus, positionapplied } = userInform;
        if(fname.trim() === '' ) { setValidate({bool:true, msg:'Write first name.'});  return; }
        if(lname.trim() === '' ) { setValidate({bool:true, msg:'Write last name.'});  return; }
        if(designation.trim() === '') {setValidate({bool:true, msg:'Write correct designation.'}); return;} 
        if(usermail.trim() === '' || !validateEmail(usermail) ) {setValidate({bool:true, msg:'Write correct email.'}); return;}
        if(userstate.trim() === '' ) {setValidate({bool:true, msg:'Write correct state/province.'}); return;}
        if(usercountry.trim() === '' ) {setValidate({bool:true, msg:'Write correct country name.'}); return;}
        if(companyname.trim() === '' ) {setValidate({bool:true, msg:'Company name can not be left blank.'}); return;}
        if(compsite.trim() === '' ) {setValidate({bool:true, msg:'Company website can not be left blank.'}); return;}
        // if(careaexp.trim() === '' ) {setValidate({bool:true, msg:'Write core experience.'}); return;}
        // if(totalexp.trim() === '' ) {setValidate({bool:true, msg:'Write total experience.'}); return;}
        // if(positionapplied.trim() === '' ) {setValidate({bool:true, msg:'Write position applied.'}); return;}

        Axios.post('/supplymail.php', {name: `${fname} ${lname}`, email:usermail, designation, userstate, usercountry, companyname, compsite, compregnumber, compdesc, compyears, compemployee, compprimaryfocus, positionapplied})
        .then(res => console.log('res ', res))
        .catch(error => console.log('error ', error))
        
        setStatus(true)
      }

      const isEmptyObj = (obj) => {
        let resultparam =0;
        Object.values(obj).forEach(entry =>{
            if(String(entry).trim() === ""){
              resultparam = 1;
            }
        })
        if(resultparam === 1) return false;
        return true;
    }

      useEffect(()=>{
        if(isEmptyObj(userInform)){
            setValidate({bool: false, msg:""})
        } else {
            setValidate({bool: true})
        }
      }, [userInform])

      const handleEmail = (event) => {
        event.preventDefault()
        const {id, value} = event.target;

        if(!validateEmail(value)){
            const tempUserInform = {...userInform};
            tempUserInform[id] = value;
            setUserInform(tempUserInform);

            const tempFirstInform = {...firstInform}
            tempFirstInform[id] = true;
            setFirstInform(tempFirstInform);
        } else {
            const tempUserInform = {...userInform};
            tempUserInform[id] = value;
            setUserInform(tempUserInform);

            const tempFirstInform = {...firstInform}
            tempFirstInform[id] = false;
            setFirstInform(tempFirstInform);
        }
      }
      const handleInput = (event) => {
        event.preventDefault()
        const {id, value} = event.target;
        let inputValue = value.replace(/[^0-9]/g, '');
        if(id === "fname" || id === "lname" || id === "designation" || id === "userstate" || id === "usercountry"){
            inputValue = value.replace(/[^A-Za-z]/g, '');
        }
        const tempUserInform = {...userInform};
        tempUserInform[id] = inputValue;
        setUserInform(tempUserInform);

        const tempFirstInform = {...firstInform}
        tempFirstInform[id] = true;
        setFirstInform(tempFirstInform);
      }
      const callUser = (event) =>{
        event.preventDefault();
        setStatus(false);
        const {id, value} = event.target;
        
        const tempUserInform = {...userInform};
        tempUserInform[id] = value;
        setUserInform(tempUserInform);
        
        const tempFirstInform = {...firstInform}
        tempFirstInform[id] = true;
        setFirstInform(tempFirstInform);
    }


    return (
        <Grid className= {styles.supplyForm_container} 
            sx={{paddingTop:"0px", paddingBottom:"120px", flexFlow:"column", alignItems:'center'}}>
            <Grid className={styles.supplyForm_subcontainer} sx={{borderBottom:"1px solid #CEE86B"}}>
                    <img src= {isMobile? "/assets/images/supply_tab1_mobile.png" : "/assets/images/supply_tab1.jpg"} alt="" />
                    <Grid sx={{backgroundColor:"#ECF2F6", padding: !isMobile? "4rem 2rem 0rem": "4rem 0rem 0rem"}} >
                        <Grid sx={{marginBottom:"2rem",paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                            <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                                Become a Supply Partner
                            </Typography>
                        </Grid>
                        <Grid className={styles.supplyForm_subcontainer} 
                            sx={{display:"flex", flexFlow: isMobile?"column-reverse":"row", gap:"2rem", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                            <Grid sx={{width:{xs:"100%", sm:"60%"}}}>
                                <Typography variant="body2" component={"p"} className={styles.supplyForm_content}>
                                Partner with Simon India as a trusted supply partner and gain access to long-term opportunities in large-scale projects across industries like chemicals, fertilizers, oil & gas, and renewable energy
<br/><br/>
We prioritize quality, reliability, and timely delivery, ensuring your contributions are integral to the success of every project. Join us in delivering world-class solutions and driving innovation across industries.

                                </Typography>
                            </Grid>
                            <Grid sx={{width:{xs:"100%", sm:"40%", display:"flex", justifyContent:"center", alignItems:"center"}}}>
                                <img src="/assets/images/supplytab1_icon.png" alt=""/>
                            </Grid>
                        </Grid>
                        <Grid sx={{position:"relative", width:"100%", height:"100px", display:"flex", justifyContent:"center"}}>
                            <Typography className={styles.provide_btn} 
                                >Provide the information <KeyboardDoubleArrowDownIcon /></Typography>
                        </Grid>
                        
                    </Grid>
            </Grid>
            <Grid id="about-form" name="about-form" className={styles.supplyForm_subcontainer} 
                sx={{marginTop:"120px", paddingLeft: isMobile? "18px":"0px", paddingRight: isMobile? "18px":"0px" }}>
                <Typography variant="h3" component={"h3"} className={styles.supplyForm_title}>
                    About Your Organization
                </Typography>
                <Typography variant="body2" component={"p"} className={styles.applyForm_content}>
                    All fields are mandatory
                </Typography>
                <Grid sx={{marginTop:"2rem"}}>
                    {status && <Typography variant='body2' component={'h4'} className='normal-text text-color' sx={{fontSize:'1.25 !important', color: "#2182FF"}} >Thank you for reaching out and sharing your details. A team member will get in touch with you shortly.</Typography>}
                <form>
                    { !status && <>
                        <Grid sx={{width:{xs:"90%", sm:"65%"}}}>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='companyname' className={styles.supplyForm_content}>Company name:</label>
                                <input type='text' id='companyname' name='companyname' value={userInform.companyname} placeholder='' required autoComplete='off'
                                    onChange={callUser} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["companyname"] === true && userInform["companyname"].trim() === "" ? "Company name can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compsite' className={styles.supplyForm_content}>Company website:</label>
                                <input type='text' id='compsite' name='compsite' value={userInform.compsite} placeholder='' required autoComplete='off' 
                                    pattern="https://.*"
                                    onChange={callUser} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["compsite"] === true && userInform["compsite"].trim() === "" ? "Company website can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compregnumber' className={styles.supplyForm_content}>Company registration number:</label>
                                <input type='text' id='compregnumber' name='compregnumber' value={userInform.compregnumber} placeholder='' required autoComplete='off'
                                    onChange={callUser} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["compregnumber"] === true && userInform["compregnumber"].trim() === "" ? "Company registration number can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compdesc' className={styles.supplyForm_content}>Company description:</label>
                                <textarea  type='text' id='compdesc' name='compdesc' value={userInform.compdesc} placeholder='' required autoComplete='off'
                                    onChange={callUser} className={styles.inputStyle} style={{height:'4rem'}} rows={3} />
                                    <div style={{color:"red"}}>{firstInform["compdesc"] === true && userInform["compdesc"].trim() === "" ? "Company description can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compyears' className={styles.supplyForm_content}>Years in business:</label>
                                <input type='text' id='compyears' name='compyears' inputmode="numeric" value={userInform.compyears} placeholder='' required autoComplete='off' pattern='^[0-9]+$'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["compyears"] === true && userInform["compyears"].trim() === "" ? "Business years can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compemployee' className={styles.supplyForm_content}>Number of employees:</label>
                                <input type='text' id='compemployee' name='compemployee' value={userInform.compemployee} placeholder='' required autoComplete='off' pattern='^[0-9]+$'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["compemployee"] === true && userInform["compemployee"].trim() === "" ? "Employee information can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='compprimaryfocus' className={styles.supplyForm_content}>Primary industry focus:</label>
                                <input type='text' id='compprimaryfocus' name='compprimaryfocus' value={userInform.compprimaryfocus} placeholder='' required autoComplete='off'
                                    onChange={callUser} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["compprimaryfocus"] === true && userInform["compprimaryfocus"].trim() === "" ? "Primary focus can not be blank":""}</div>
                            </Grid>
                            {/* <Grid className={styles.formGrid}>
                                <label htmlFor='positionapplied' className={styles.supplyForm_content}>Position applying for:</label>
                                <input type='text' id='positionapplied' name='positionapplied' value={userInform.positionapplied} placeholder='' required autoComplete='off'
                                    onChange={callUser} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["positionapplied"] === true && userInform["positionapplied"].trim() === "" ? "Applier position can not be blank":""}</div>
                            </Grid> */}
                            <Typography variant="h3" component={"h3"} className={styles.supplyForm_title} sx={{marginTop:"3rem"}}>
                                About you
                            </Typography>
                            <Typography variant="body2" component={"p"} className={styles.applyForm_content} sx={{ marginBottom:"2rem"}}>
                                All fields are mandatory
                            </Typography>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='fname' className={styles.supplyForm_content}>First name:</label>
                                <input type='text' id='fname' name='fname' value={userInform.fname} placeholder='' required autoComplete='off' pattern="^[A-Za-z]+$"
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["fname"] === true && userInform["fname"].trim() === "" ? "First name can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='lname' className={styles.supplyForm_content}>Last name:</label>
                                <input type='text' id='lname' name='lname' value={userInform.lname} placeholder='' required autoComplete='off'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["lname"] === true && userInform["lname"].trim() === "" ? "Last name can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='designation' className={styles.supplyForm_content}>Designation/ Role:</label>
                                <input type='text' id='designation' name='designation' value={userInform.designation} placeholder='' required autoComplete='off'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["designation"] === true && userInform["designation"].trim() === "" ? "Designation/Role can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='usermail' className={styles.supplyForm_content}>Email:</label>
                                <input type='email' id='usermail' name='usermail' value={userInform.usermail} placeholder='' required validate autoComplete='off'
                                    onChange={handleEmail} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["usermail"] === true ? "User email should be properly formatted":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='userstate' className={styles.supplyForm_content}>State/Province:</label>
                                <input type='text' id='userstate' name='userstate' value={userInform.userstate} placeholder='' required autoComplete='off'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["userstate"] === true && userInform["userstate"].trim() === "" ? "State can not be blank":""}</div>
                            </Grid>
                            <Grid className={styles.formGrid}>
                                <label htmlFor='usercountry' className={styles.supplyForm_content}>Country:</label>
                                <input type='text' id='usercountry' name='usercountry' value={userInform.usercountry} placeholder='' required autoComplete='off'
                                    onInput={handleInput} className={styles.inputStyle} />
                                    <div style={{color:"red"}}>{firstInform["usercountry"] === true && userInform["usercountry"].trim() === "" ? "Country name can not be blank":""}</div>
                            </Grid>
                        </Grid>
                        </> 
                    }
                        {validate.bool && !status && <Typography variant='body1' component={'p'} sx={{py:1, color:"red"}} >{validate.msg}</Typography>}
                        {!status && <Grid item sx={{display:'flex', columnGap:'0.5em', alignItems:'center', justifyContent:'flex-start', mt:'2em', width:'100%'}}>
                            <Button variant='contained' 
                                disabled={validate.bool ? "disabled": ""}
                                className= {styles.supplyForm_btn}
                                endIcon={<East color="#FFFFFF" />}
                                onClick={handleSubmit}
                            >Submit form</Button>
                        </Grid>
                    }
                        
                    </form>
                </Grid>

            </Grid>
        </Grid>
    )

}

export default SupplyForm;