
import React, { useRef } from 'react';
import Slider from 'react-slick';

const HomeClients = () => {
    const sliderRef = useRef(null);
    const imagePath = "/assets/images/clients/";
    const settings = {
        dots: false,
        arrows: false,
        centerPadding: '30px',
        infinite: true,
        cssEase: "linear",
        speed: 5000,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: false,
        autoplay: true,
        autoplaySpeed: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 450,
            settings: {
              arrows: false,
              dots: false,
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
        ]
      }
    const clientData = [
        <div><img src= {`${imagePath}client_1.png`} alt="client1" /></div>,
        <div><img src= {`${imagePath}client_2.png`} alt="client2" /></div>,
        <div><img src= {`${imagePath}client_3.png`} alt="client3" /></div>,
        <div><img src= {`${imagePath}client_4.png`} alt="client4" /></div>,
        <div><img src= {`${imagePath}client_5.png`} alt="client5" /></div>,
        <div><img src= {`${imagePath}client_6.png`} alt="client6" /></div>,
        <div><img src= {`${imagePath}client_7.png`} alt="client7" /></div>,
        <div><img src= {`${imagePath}client_8.png`} alt="client8" /></div>,
        <div><img src= {`${imagePath}client_9.png`} alt="client9" /></div>,
        <div><img src= {`${imagePath}client_10.png`} alt="client10" /></div>,
        <div><img src= {`${imagePath}client_11.png`} alt="client11" /></div>
    ]


return (
    <div style={{width: "100%"}}>
        <Slider ref={sliderRef} {...settings}>
            {clientData}
        </Slider>
    </div>
)

}

export default HomeClients;