import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
import PageContent from '../../components/pageContent';
import SafetyOrder from './safetyOrder';
import SafetyQuality from './safetyQuality';
import SafetyTips from './safetyTips';


import { isMobile } from 'react-device-detect';


const ProjectQuality = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Projects', url: '/'},
        {title: 'Quality & safety', url: ''}
    ]
    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])


    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= {isMobile ? "Quality & Safety" : "Quality &<br/> Safety"} bannerimage="project_quality_banner.png" bannerPath={bannerPath} />
            <PageContent details="At Simon India, Quality and Safety are not just priorities; they are core values that shape every aspect of our operations. We are committed to maintaining the highest standards of safety and quality across all our projects, ensuring the well-being of our employees, partners, and clients. Through rigorous procedures, breakthrough technologies, and a culture of continuous improvement, we deliver excellence while safeguarding every stage of the process engineering."  />
            <SafetyOrder />
            <SafetyTips />
            <SafetyQuality />
        </Grid>
    )
}

export default ProjectQuality;
        