import React, { useState , useRef } from 'react';
import {Drawer, List, ListItemText, Collapse, Link, IconButton, Paper, ListItemButton} from '@mui/material'
import Grid from '@mui/material/Grid2';
import {Close, ExpandLess, ExpandMore} from'@mui/icons-material';
import styles from './header.module.css';
import { } from './header.css';


const MobileHeaderMenu = () => {

    const [menuOpen, setMenuOpen] = useState(false);
    const [knowOpen, setKnowOpen] = useState(false);
    const [projectOpen, setProjectOpen] = useState(false);
    const [joinOpen, setJoinOpen] = useState(false);
    
    const linkStyle ={ width: '100%', padding: '1em 0px 1em 1em', borderBottom: "1px solid #C1C1C1", textDecoration:'none'}
    const anchorRef = useRef(null);
    const anchorProjectRef = useRef(null);
    const anchorJoinRef = useRef(null);


    const handleToggle = () => {
        setMenuOpen((prevOpen) => !prevOpen);
      };

      const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setMenuOpen(false);
      };
    
      const prevOpen = React.useRef(menuOpen);
      React.useEffect(() => {
        if (prevOpen.current === true && menuOpen === false) {
          anchorRef.current.focus();
        }
    
        prevOpen.current = menuOpen;
      }, [menuOpen]);

      const handleClick = (pLabel) => {
        if(pLabel !== "know")setKnowOpen(false);
        if(pLabel !== "projects")setProjectOpen(false);
        if(pLabel !== "join")setJoinOpen(false);
        if(pLabel === "know"){
          setKnowOpen(!knowOpen);
        } else if(pLabel === "projects") {
          setProjectOpen(!projectOpen);
        } else if(pLabel === "join") {
          setJoinOpen(!joinOpen);
        }
        
      };
    
    return (
        <Grid>
            <IconButton
                ref={anchorRef}
                style={{
                    color:"#FFF", width:50, height:10, backgroundColor:'transparent', borderRadius:10, paddingRight:0, paddingTop:0
                }}
            id="composition-button"
            aria-controls={menuOpen ? 'composition-menu' : undefined}
            aria-expanded={menuOpen ? 'true' : undefined}
            aria-haspopup="true"
            onClick={() => handleToggle()}>
                <div className= {!menuOpen ? 'icon' : 'icon iconOpened'}>
                <span className= {!menuOpen ? 'menu-icon menu-bar' : 'menu-icon menu-bar-opened'}></span>
                <span className= {!menuOpen ? 'menu-icon' : 'menu-icon menu-iconOpened'}></span>
                </div>
          </IconButton>
          {
            <Drawer
                open={menuOpen}
                onClose={() => handleToggle()}
                // anchorEl={anchorRef.current}
                // role={undefined}
                sx={{width:'100%', height: "100%"}}
                // placement="bottom-start"
                // transition
                // disablePortal
            >
                {/* {({ TransitionProps, placement }) => (
            
            <Grow
              {...TransitionProps}
              style={{
                marginTop:10,
                
                transformOrigin:
                  placement === 'bottom-start' ? 'top' : 'top',
              }}
            > */}
            <Paper sx={{width:"100%", boxShadow: "none"}}>
                
            {/* <ClickAwayListener onClickAway={handleClose}> */}
                <div style={{width:'100%', maxWidth: '100%', height:"100%", display:'flex', justifyContent:'center', textAlign:'left', padding:'1em 0px'}}>
                  <div style={{width:'100%', display:'flex', flexFlow:'column'}}>

                  <List
                    sx={{paddingLeft: 0, width: '100%', display:'flex', flexFlow:'column', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                  >
                    <Grid sx={{display: 'flex', justifyContent:'space-between', padding:"0px 20px 20px", alignItems:'center'}}>
                      <img src="/assets/images/logo.png" style={{width:"80px", height:"auto", borderRadius: 5}} alt="" />
                      <IconButton sx={{color:"#2182FF"}} onClick={() => handleToggle()}><Close /></IconButton>
                    </Grid>
                    <Link href={"/services"} className='link-color' style={linkStyle} onClick={handleClose} >Services</Link>
                    <Link href='/#' className='link-color' style={linkStyle} onClick={handleClose}>Expertise</Link>
                    <ListItemButton onClick={() => handleClick("projects")} sx={{padding:"0.5em 0px 0px",borderBottom: "1px solid #C1C1C1", alignItems:"flex-start"}}>
                        <ListItemText primary="Projects" sx={{padding:"0px 1em 0.5em", color:'#2182FF'}} />
                        {projectOpen ? <ExpandLess sx={{color:'#2182FF', marginRight: "15px"}} /> : <ExpandMore sx={{color:'#2182FF', marginRight: "15px"}} />}
                      </ListItemButton>
                      <Collapse in={projectOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding 
                          sx={{display:"flex", flexFlow: "column"}}>
                            <div style={{textAlign:'center'}}>
                              <img src="/assets/images/projectsmenu_mobile.png" alt="" 
                              style={{padding: "1rem 0.5rem 0.5rem", width: "fit-content"}} />
                              </div>
                            <Link href='/project-gallery' style={linkStyle} className={`link-color ${styles.menucolor}`} onClick={handleClose}>Project gallery</Link>
                            <Link href='/project-quality' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>Quality & safety</Link>
                            <Link href='/faq' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>FAQs</Link>
                        </List>
                      </Collapse>
                    <ListItemButton onClick={() => handleClick("know")} sx={{padding:"0.5em 0px 0px",borderBottom: "1px solid #C1C1C1", alignItems:"flex-start"}}>
                        <ListItemText primary="Know us" sx={{padding:"0px 1em 0.5em", color:'#2182FF'}} />
                        {knowOpen ? <ExpandLess sx={{color:'#2182FF', marginRight: "15px"}} /> : <ExpandMore sx={{color:'#2182FF', marginRight: "15px"}} />}
                      
                      </ListItemButton>
                      <Collapse in={knowOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding 
                          sx={{display:"flex", flexFlow: "column"}}>
                            <div style={{textAlign:'center'}}>
                              <img src="/assets/images/aboutmenu_mobile.png" alt="" 
                              style={{padding: "1rem 0.5rem 0.5rem", width: "fit-content"}} />
                              </div>
                            <Link href='/about' style={linkStyle} className={`link-color ${styles.menucolor}`} onClick={handleClose}>About us</Link>
                            <Link href='/value-preposition' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>Our value proposition</Link>
                            <Link href='/leadership' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>Leadership team</Link>
                            <Link href={"/sustainability"} className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose} >Sustainability</Link>
                        </List>
                      </Collapse>
                    
                    
                    <Link href="/news" className='link-color' style={linkStyle} onClick={handleClose} >What's new</Link>
                    <ListItemButton onClick={() => handleClick("join")} sx={{padding:"0.5em 0px 0px",borderBottom: "1px solid #C1C1C1", alignItems:"flex-start"}}>
                        <ListItemText primary="Join us" sx={{padding:"0px 1em 0.5em", color:'#2182FF'}} />
                        {joinOpen ? <ExpandLess sx={{color:'#2182FF', marginRight: "15px"}} /> : <ExpandMore sx={{color:'#2182FF', marginRight: "15px"}} />}
                      
                      </ListItemButton>
                      <Collapse in={joinOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding 
                          sx={{display:"flex", flexFlow: "column"}}>
                            <div style={{textAlign:'center'}}>
                              <img src="/assets/images/joinmenu_mobile.png" alt="" 
                              style={{padding: "1rem 0.5rem 0.5rem", width: "fit-content"}} />
                              </div>
                            <Link href='/our-culture' style={linkStyle} className={`link-color ${styles.menucolor}`} onClick={handleClose}>Our culture</Link>
                            <Link href='/grow-together/supply' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>Grow together</Link>
                            <Link href='/apply' className={`link-color ${styles.menucolor}`} style={linkStyle} onClick={handleClose}>Apply</Link>
                        </List>
                      </Collapse>
                    
                    



                  </List>


                    
                  </div>
                </div>
            {/* </ClickAwayListener> */}
            </Paper>
            {/* </Grow>
            )} */}
            </Drawer>
        }

        </Grid>
    )

}

export default MobileHeaderMenu;