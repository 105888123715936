import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid2'

import PageBanner from '../../components/pageBanner';
import PageContent from '../../components/pageContent';
import HomeProjects from '../../components/homeProjects';
import ProjectDetail from './projectDetail';
import ProjectExplore from './projectExplore';
import ProjectAll from './projectAll';
import { isMobile } from 'react-device-detect';



const ProjectGallery = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Projects', url: '/'},
        {title: 'Project gallery', url: ''}
    ]

    useEffect(()=>{
        window.scrollTo({top:0, left:0})
    },[])


    return (
        <Grid sx={{display: 'flex', flexFlow:'column', width: '100%'}}>
            <PageBanner title= {isMobile ? "Project Gallery" : "Project Gallery"} bannerimage="projectgallery_banner.jpg" bannerPath={bannerPath} />
            <PageContent details="Welcome to Simon India’s Project Gallery, where we showcase our diverse portfolio of 75+ successful projects spanning 4 countries and key industries such as oil & gas, chemicals, fertilizers, and renewable energy. With each project, we demonstrate our commitment to delivering world-class engineering, procurement, and construction solutions that meet the highest global standards."  />
            <ProjectDetail />
            <HomeProjects isHome={false} />
            <ProjectExplore />
            <ProjectAll />
            {/* <Board /> */}
            
            
            {/* <AdvantageC /> */}
        </Grid>
    )

}

export default ProjectGallery;

