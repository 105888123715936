import React, {useEffect} from 'react';
import Grid from '@mui/material/Grid2';
import PageBanner from '../../components/pageBanner';
// import AdvantageC from '../../components/advantageC';
import PageContent from '../../components/pageContent';
import CultureProject from './cultureProject';
import PeopleCulture from './peopleCulture';
import CulturePeople from './culturepeople'
import CultureExplore from './cultureExplore';
// import CultureGallery from './cultureGallery';
import ProjectClient from '../services/projectClient';
// import FaqContainer from './faqContainer';


const OurCulture = () => {

    const bannerPath = [
        {title: 'Home', url: '/'},
        {title: 'Join us', url: ''},
        {title: "Our culture", url: ''}
    ]

    useEffect(()=>{
            window.scrollTo({top:0, left:0})
    },[])


return (
    <Grid sx={{width: "100%"}}>
        <PageBanner title="Our culture" bannerimage="culture_banner.jpg" 
            bannerPath={bannerPath}  />
        <PageContent 
           details="At Simon India, our culture is built on the foundation of hard work, camaraderie, and celebration. We consider ourselves a second family, coming together not just to meet our professional goals but to share in the joy of personal achievements as well as festivities like Holi, Diwali, Bihu, Christmas, and Eid. 
<br/><br/>
Our collaborative spirit is evident in everything we do, from working tirelessly on projects to engaging in fun and celebrations. This close-knit environment fosters a sense of belonging and mutual respect, coming together as a family.
The warm and friendly atmosphere fosters the desire to succeed as a team and uphold our core values of integrity, accountability, adaptability, and an employee-first approach. This further helps build competent teams that trust each other and work towards common organizational goals. 
<br/><br/>
We are committed to improving client experiences through ethical decision-making and a positive impact on the industry and environment. This dedication to excellence and responsibility not only strengthens our brand but also ensures that we serve our clients effectively and efficiently, building long-lasting and trustworthy relationships.
" />
    <PeopleCulture />
    {/* <CultureGallery /> */}
    <CulturePeople />
    <ProjectClient 
        quotation="At Simon India, value creation has been a core principle since the beginning. As a leading EPCM company, Simon stands out by fostering a unique blend of cultural values. The trust placed in its skilled employees empowers them to 'think right, act right,' making each individual feel valued and integral to the company’s success."
        client="Pranjali Chakraborty" 
        designation="Manager, Corporate Communications- Zuari Industries Limited"
    />
    <CultureExplore />
    <CultureProject />
    </Grid>
)

}

export default OurCulture;